import {
  Module,
  VuexModule,
  Action,
  Mutation,
  getModule
} from 'vuex-module-decorators'
import {
  MasterCategory,
  MoveMasterCategoriesDto,
  UpdateMasterCategory
} from './types'
import { defaultMasterCategoryApi } from './MasterCategoryApi'
import store from '../store'
import router from '@/router'

@Module({
  dynamic: true,
  name: 'masterCategoryModule',
  store
})
export default class MasterCategoryModule extends VuexModule {
  public masterCategories: MasterCategory[] = []

  @Mutation
  private setMasterCategories(masterCategories: MasterCategory[]): void {
    if (this.masterCategories) {
      this.masterCategories = masterCategories
    }
  }

  @Mutation
  private setMasterCategory(masterCategory: MasterCategory): void {
    if (this.masterCategories) {
      const index = this.masterCategories.findIndex(
        c => c.id === masterCategory.id
      )
      if (index) {
        this.masterCategories[index] = masterCategory
      }
    }
  }

  @Action({ commit: 'setMasterCategories' })
  async loadAllMasterCategories(): Promise<MasterCategory[]> {
    const budgetId = router.currentRoute.params.budgetId
    return defaultMasterCategoryApi().fetchMasterCategories(budgetId)
  }

  @Action({ commit: 'setMasterCategory' })
  async updateMasterCategory(
    masterCategory: UpdateMasterCategory
  ): Promise<MasterCategory> {
    const budgetId = router.currentRoute.params.budgetId
    return defaultMasterCategoryApi().updateMasterCategory(
      budgetId,
      masterCategory
    )
  }

  @Action
  async deleteMasterCategory(
    masterCategoryId: string
  ): Promise<MasterCategory[]> {
    const budgetId = router.currentRoute.params.budgetId
    return defaultMasterCategoryApi()
      .deleteMasterCategory(budgetId, masterCategoryId)
      .then(() => this.loadAllMasterCategories())
  }

  @Action
  async moveMasterCategories(
    moveMasterCategories: MoveMasterCategoriesDto
  ): Promise<MasterCategory[]> {
    const budgetId = router.currentRoute.params.budgetId
    return defaultMasterCategoryApi()
      .moveMasterCategories(budgetId, moveMasterCategories)
      .then(() => this.loadAllMasterCategories())
  }

  get masterCategory() {
    return (masterCategoryId: string): MasterCategory | undefined =>
      this.masterCategories.find(c => c.id === masterCategoryId)
  }
}

export const masterCategoryModule = getModule(MasterCategoryModule)
