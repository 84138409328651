import axios from '@/backend/axios'
import {
  Account,
  CreateAccount,
  Balance,
  UpdateAccount,
  AccountReconciliation
} from './types'

export default interface AccountApi {
  fetchAccounts(budgetId: string): Promise<Array<Account>>
  createAccount(budgetId: string, account: CreateAccount): Promise<Account>
  updateAccount(budgetId: string, account: UpdateAccount): Promise<Account>
  fetchBalance(budgetId: string, accountId?: string): Promise<Balance>
  reconcileAccount(
    budgetId: string,
    accountReconciliation: AccountReconciliation
  ): Promise<void>
}

class RealAccountApi implements AccountApi {
  private httpClient: any

  public constructor(httpClient: any) {
    this.httpClient = httpClient
  }

  public async fetchAccounts(budgetId: string): Promise<Array<Account>> {
    const response = await this.httpClient.get(`${budgetId}/accounts`)
    return response.data as Array<Account>
  }

  async createAccount(
    budgetId: string,
    account: CreateAccount
  ): Promise<Account> {
    const response = await this.httpClient.post(`${budgetId}/accounts`, account)
    return response.data as Account
  }

  async updateAccount(
    budgetId: string,
    account: UpdateAccount
  ): Promise<Account> {
    const response = await this.httpClient.put(`${budgetId}/accounts`, account)
    return response.data as Account
  }

  async fetchBalance(budgetId: string, accountId?: string): Promise<Balance> {
    let url = '/accounts/balance'
    if (accountId) {
      url = `/${budgetId}/accounts/${accountId}/balance`
    }
    const response = await this.httpClient.get(url)
    return { ...response.data }
  }

  async reconcileAccount(
    budgetId: string,
    accountReconciliation: AccountReconciliation
  ): Promise<void> {
    const url = `/${budgetId}/accounts/reconcile`
    return await this.httpClient.put(url, accountReconciliation)
  }
}

export const createAccountApi = (httpClient: any): AccountApi => {
  return new RealAccountApi(httpClient)
}

export const defaultAccountApi = (): AccountApi => {
  return createAccountApi(axios)
}
