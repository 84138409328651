import Vue from 'vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import App from './App.vue'
import router from './router'
import axios from './backend/axios'
import store from './store/store'
import VueRouter from 'vue-router'
import VueMq from 'vue-mq'
import './quasar'

Vue.config.productionTip = false

Vue.use(Buefy, axios)
Vue.use(VueRouter)
Vue.use(VueMq, {
  breakpoints: {
    mobile: 769,
    tablet: 1023,
    desktop: 1215,
    widescreen: 1407,
    fullhd: Infinity
  }
})

const vue = new Vue({
  router,
  store,
  data: {
    hasFocus: true
  },
  render: h => h(App)
}).$mount('#app')
