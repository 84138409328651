import axios from '@/backend/axios'
import { Payee, CreatePayee, UpdatePayee } from './types'

export default interface PayeeApi {
  fetchPayees(budgetId: string, searchInput?: string): Promise<Array<Payee>>
  createPayee(budgetId: string, category: CreatePayee): Promise<Payee>
  updatePayee(budgetId: string, payee: UpdatePayee): Promise<Payee>
}

class RealPayeeApi implements PayeeApi {
  private httpClient: any

  public constructor(httpClient: any) {
    this.httpClient = httpClient
  }

  public async fetchPayees(budgetId: string): Promise<Array<Payee>> {
    const response = await this.httpClient.get(`${budgetId}/payees`)
    return response.data as Array<Payee>
  }

  async createPayee(budgetId: string, payee: CreatePayee): Promise<Payee> {
    const response = await this.httpClient.post(`${budgetId}/payees`, payee)
    return response.data as Payee
  }

  async updatePayee(budgetId: string, payee: UpdatePayee): Promise<Payee> {
    const response = await this.httpClient.put(`${budgetId}/payees`, payee)
    return response.data as Payee
  }
}

export const createPayeeApi = (httpClient: any): PayeeApi => {
  return new RealPayeeApi(httpClient)
}

export const defaultPayeeApi = (): PayeeApi => {
  return createPayeeApi(axios)
}
