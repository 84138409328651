import axios from '@/backend/axios'
import {
  AuthRequest,
  AuthResponse,
  SignupRequest,
  UserInfo,
  Budget,
  CreateBudget,
  AddUserRequest
} from './types'
import { AxiosInstance } from 'axios'

export default interface UserApi {
  login(authRequest: AuthRequest): Promise<AuthResponse>
  signup(signupRequest: SignupRequest): Promise<AuthResponse>
  userInfo(): Promise<UserInfo>
  createBudget(createBudget: CreateBudget): Promise<Budget>
  addUser(addUserRequest: AddUserRequest): Promise<void>
  refreshToken(): Promise<AuthResponse>
}

class RealUserApi implements UserApi {
  private httpClient: AxiosInstance

  public constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient
  }

  public async login(authRequest: AuthRequest): Promise<AuthResponse> {
    return axios
      .post('/login', authRequest)
      .then(response => response.data)
      .catch(error => error.response.data)
  }

  public async signup(signupRequest: SignupRequest): Promise<AuthResponse> {
    return axios
      .post('/signup', signupRequest)
      .then(response => response.data)
      .catch(error => error.response.data)
  }

  public async userInfo(): Promise<UserInfo> {
    return axios
      .get('/userInfo')
      .then(response => response.data)
      .catch(error => error.response.data)
  }

  public async createBudget(createBudget: CreateBudget): Promise<Budget> {
    return axios
      .post('/budgets', createBudget)
      .then(response => response.data)
      .catch(error => error.response.data)
  }

  public async addUser(addUserRequest: AddUserRequest): Promise<void> {
    return axios
      .post(`/${addUserRequest.budgetId}/addUser`, addUserRequest)
      .then(response => response.data)
      .catch(error => error.response.data)
  }

  public async refreshToken(): Promise<AuthResponse> {
    return axios
      .get('/token')
      .then(response => response.data)
      .catch(error => error.response.data)
  }
}

export const createUserApi = (httpClient: any): UserApi => {
  return new RealUserApi(httpClient)
}

export const defaultUserApi = (): UserApi => {
  return createUserApi(axios)
}
