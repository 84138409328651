import {
  Module,
  VuexModule,
  Action,
  Mutation,
  getModule
} from 'vuex-module-decorators'
import { Category, CreateCategory, UpdateCategory } from './types'
import { defaultCategoryApi } from './CategoryApi'
import store from '../store'
import router from '@/router'
import Utils from '@/utils'

@Module({
  dynamic: true,
  name: 'categoryModule',
  store
})
export default class CategoryModule extends VuexModule {
  public categories: Category[] = []

  @Mutation
  private addCategory(category: Category): void {
    if (this.categories) {
      this.categories.push(category)
      this.categories = Utils.sortEntities(this.categories)
    }
  }

  @Mutation
  private setCategory(category: Category): void {
    if (this.categories) {
      const index = this.categories.findIndex(c => c.id === category.id)
      if (index) {
        this.categories[index] = category
      }
      this.categories = Utils.sortEntities(this.categories)
    }
  }

  @Mutation
  private setCategories(categories: Category[]): void {
    if (this.categories) {
      this.categories = Utils.sortEntities(categories)
    }
  }

  @Action({ commit: 'setCategories' })
  public async loadAllCategories(): Promise<Category[]> {
    const budgetId = router.currentRoute.params.budgetId
    return defaultCategoryApi().fetchCategories(budgetId)
  }

  @Action({ commit: 'addCategory' })
  public async createCategory(category: CreateCategory): Promise<Category> {
    const budgetId = router.currentRoute.params.budgetId
    return defaultCategoryApi().createCategory(budgetId, category)
  }

  @Action({ commit: 'setCategory' })
  public async updateCategory(category: UpdateCategory): Promise<Category> {
    const budgetId = router.currentRoute.params.budgetId
    return defaultCategoryApi().updateCategory(budgetId, category)
  }

  public get category() {
    return (categoryId: string): Category | undefined =>
      this.categories.find(c => c.id === categoryId)
  }
}

export const categoryModule = getModule(CategoryModule)
