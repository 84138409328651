import axios from '@/backend/axios'
import {
  MasterCategory,
  UpdateMasterCategory,
  MoveMasterCategoriesDto
} from './types'

export default interface MasterCategoryApi {
  fetchMasterCategories(budgetId: string): Promise<Array<MasterCategory>>
  updateMasterCategory(
    budgetId: string,
    masterCategory: UpdateMasterCategory
  ): Promise<MasterCategory>
  deleteMasterCategory(
    budgetId: string,
    masterCategoryId: string
  ): Promise<void>
  moveMasterCategories(
    budgetId: string,
    moveMasterCategoriesDto: MoveMasterCategoriesDto
  ): Promise<void>
}

class RealMasterCategoryApi implements MasterCategoryApi {
  private httpClient: any

  public constructor(httpClient: any) {
    this.httpClient = httpClient
  }

  public async fetchMasterCategories(
    budgetId: string
  ): Promise<Array<MasterCategory>> {
    const response = await this.httpClient.get(`${budgetId}/master-categories`)
    return response.data as Array<MasterCategory>
  }

  async updateMasterCategory(
    budgetId: string,
    masterCategory: UpdateMasterCategory
  ): Promise<MasterCategory> {
    const response = await this.httpClient.put(
      `${budgetId}/master-categories`,
      masterCategory
    )
    return response.data as MasterCategory
  }

  async deleteMasterCategory(
    budgetId: string,
    masterCategoryId: string
  ): Promise<void> {
    await this.httpClient.delete(
      `${budgetId}/master-categories/${masterCategoryId}`
    )
  }

  async moveMasterCategories(
    budgetId: string,
    moveMasterCategories: MoveMasterCategoriesDto
  ): Promise<void> {
    const response = await this.httpClient.put(
      `${budgetId}/move-master-categories`,
      moveMasterCategories
    )
    return response.data
  }
}

export const createMasterCategoryApi = (httpClient: any): MasterCategoryApi => {
  return new RealMasterCategoryApi(httpClient)
}

export const defaultMasterCategoryApi = (): MasterCategoryApi => {
  return createMasterCategoryApi(axios)
}
