import { Entity } from './types'

export default class Utils {
  static sortEntities(entities: Entity[]): Entity[] {
    return entities.sort((a, b) => {
      const lhs = a.name || ''
      const rhs = b.name || ''
      if (lhs === rhs) {
        return 0
      }
      return lhs.toLowerCase() > rhs.toLowerCase() ? 1 : -1
    })
  }

  static toCurrency(value: number): string {
    const higherNominal = value / 100
    return higherNominal.toLocaleString('de-DE', {
      style: 'currency',
      currency: 'EUR'
    })
  }

  static firstLetterGrouping(entity: Entity): string {
    if (!entity.name) {
      return ''
    }
    const firstLetter = entity.name.substring(0, 1)
    const number = Number(firstLetter)
    if (!isNaN(number)) {
      return '0…9'
    }
    return firstLetter.toLocaleUpperCase()
  }
}
