import axios from '@/backend/axios'
import { Category, CreateCategory, UpdateCategory } from './types'

export default interface CategoryApi {
  fetchCategories(budgetId: string): Promise<Array<Category>>
  createCategory(budgetId: string, category: CreateCategory): Promise<Category>
  updateCategory(budgetId: string, category: UpdateCategory): Promise<Category>
}

class RealCategoryApi implements CategoryApi {
  private httpClient: any

  public constructor(httpClient: any) {
    this.httpClient = httpClient
  }

  public async fetchCategories(budgetId: string): Promise<Array<Category>> {
    const response = await this.httpClient.get(`${budgetId}/categories`)
    return response.data as Array<Category>
  }

  async createCategory(
    budgetId: string,
    category: CreateCategory
  ): Promise<Category> {
    const response = await this.httpClient.post(
      `${budgetId}/categories`,
      category
    )
    return response.data as Category
  }

  async updateCategory(
    budgetId: string,
    category: UpdateCategory
  ): Promise<Category> {
    const response = await this.httpClient.put(
      `${budgetId}/categories`,
      category
    )
    return response.data as Category
  }
}

export const createCategoryApi = (httpClient: any): CategoryApi => {
  return new RealCategoryApi(httpClient)
}

export const defaultCategoryApi = (): CategoryApi => {
  return createCategoryApi(axios)
}
