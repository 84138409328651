import {
  Module,
  VuexModule,
  Action,
  Mutation,
  MutationAction,
  getModule
} from 'vuex-module-decorators'
import { Payee, CreatePayee, UpdatePayee } from './types'
import { defaultPayeeApi } from './PayeeApi'
import store from '../store'
import router from '@/router'
import Utils from '@/utils'
import Vue from 'vue'

@Module({
  dynamic: true,
  name: 'payeeModule',
  store
})
export default class PayeeModule extends VuexModule {
  public payees: Payee[] = []

  @Mutation
  private addPayee(payee: Payee): void {
    if (this.payees) {
      this.payees.push(payee)
      this.payees = Utils.sortEntities(this.payees)
    }
  }

  @Mutation
  private setPayee(payee: Payee): void {
    if (this.payees) {
      const index = this.payees.findIndex(p => p.id === payee.id)
      Vue.set(this.payees, index, payee)
    }
  }

  @Mutation
  private setPayees(payees: Payee[]): void {
    if (this.payees) {
      this.payees = Utils.sortEntities(payees)
    }
  }

  @Action({ commit: 'setPayee' })
  public async updatePayee(payee: UpdatePayee): Promise<Payee> {
    const budgetId = router.currentRoute.params.budgetId
    const updatedPayee = await defaultPayeeApi().updatePayee(budgetId, payee)
    return updatedPayee
  }

  @Action({ commit: 'setPayees' })
  public async loadAllPayees(): Promise<any> {
    const budgetId = router.currentRoute.params.budgetId
    const payees: Payee[] = await defaultPayeeApi().fetchPayees(budgetId)
    return payees
  }

  @Action({ commit: 'addPayee' })
  public async createPayee(payee: CreatePayee): Promise<Payee> {
    const budgetId = router.currentRoute.params.budgetId
    return defaultPayeeApi().createPayee(budgetId, payee)
  }

  public get payee() {
    return (payeeId: string) => this.payees.find(c => c.id === payeeId)
  }
}

export const payeeModule = getModule(PayeeModule)
