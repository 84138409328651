import axios, { AxiosInstance } from 'axios'

const BACKEND_URL = process.env.VUE_APP_ROOT_API

const ax: AxiosInstance = axios.create({
  baseURL: BACKEND_URL,
  headers: {
    common: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.token
    }
  }
})

export default ax
