import {
  Module,
  VuexModule,
  Action,
  Mutation,
  getModule
} from 'vuex-module-decorators'
import {
  AuthRequest,
  AuthResponse,
  SignupRequest,
  UserInfo,
  Budget,
  CreateBudget,
  AddUserRequest
} from './types'
import { defaultUserApi } from './UserApi'
import store from '../store'
import axios from '@/backend/axios'
import router from '@/router'
import { AxiosResponse } from 'axios'

@Module({
  dynamic: true,
  name: 'userModule',
  store
})
export default class UserModule extends VuexModule {
  token: string | null = localStorage.getItem('token')
  error: string | null = null
  userInfo: UserInfo | null = null

  responseInterceptor = (response: AxiosResponse<any>) => response

  @Mutation
  public initializeUserModule(): void {
    const token = localStorage.getItem('token')
    if (token) {
      axios.defaults.headers.common.Authorization = 'Bearer ' + token
    }

    axios.interceptors.response.use(this.responseInterceptor, (error: any) => {
      if (localStorage.getItem('token') && error.response.status === 401) {
        axios.defaults.headers.common.Authorization = null
        localStorage.removeItem('token')
        router.push('/login')
      }
      return error.response
    })
  }

  @Mutation
  private setResponse(authResponse: AuthResponse): void {
    if (authResponse.status >= 400) {
      this.error = authResponse.error
      this.token = null
      this.userInfo = null
    } else if (authResponse.token) {
      localStorage.setItem('token', authResponse.token)
      this.token = authResponse.token
      this.error = null
      axios.defaults.headers.common.Authorization =
        'Bearer ' + authResponse.token
    }
  }

  @Mutation
  private setUserInfo(userInfo: UserInfo): void {
    this.userInfo = userInfo
  }

  @Action({ commit: 'setResponse' })
  public login(authRequest: AuthRequest): Promise<AuthResponse> {
    return defaultUserApi().login(authRequest)
  }

  @Action({ commit: 'setResponse' })
  public refreshToken(): Promise<AuthResponse> {
    return defaultUserApi().refreshToken()
  }

  @Mutation
  public logout(): void {
    localStorage.removeItem('token')
    this.token = null
    this.userInfo = null
  }

  @Action({ commit: 'setResponse' })
  public signup(signupRequest: SignupRequest): Promise<AuthResponse> {
    return defaultUserApi().signup(signupRequest)
  }

  @Action({ commit: 'setUserInfo' })
  public retrieveUserInfo(): Promise<UserInfo> {
    return defaultUserApi().userInfo()
  }

  @Action
  public createBudget(createBudget: CreateBudget): Promise<Budget> {
    return defaultUserApi().createBudget(createBudget)
  }

  @Action
  public addUser(addUserRequest: AddUserRequest): Promise<void> {
    return defaultUserApi().addUser(addUserRequest)
  }

  public get isLoggedIn(): boolean {
    if (this.token) {
      return true
    } else {
      return false
    }
  }
}

export const userModule = getModule(UserModule)
