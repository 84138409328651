import {
  Module,
  VuexModule,
  Action,
  Mutation,
  MutationAction,
  getModule
} from 'vuex-module-decorators'
import {
  Account,
  CreateAccount,
  Balance,
  UpdateAccount,
  AccountReconciliation
} from './types'
import { defaultAccountApi } from './AccountApi'
import store from '../store'
import router from '@/router'
import Vue from 'vue'
import { EventBus } from '@/EventBus'

@Module({
  dynamic: true,
  name: 'accountModule',
  store
})
export default class AccountModule extends VuexModule {
  public accounts: Account[] = []
  public balances: Balance[] = []

  @Mutation
  private addAccount(account: Account): void {
    if (this.accounts) {
      this.accounts.push(account)
    }
  }

  @Mutation
  private setAccount(account: Account): void {
    if (this.accounts) {
      const index = this.accounts.findIndex(a => a.id === account.id)
      Vue.set(this.accounts, index, account)
    }
  }

  @MutationAction({ mutate: ['accounts'] })
  public async loadAll(): Promise<any> {
    const budgetId = router.currentRoute.params.budgetId
    const accounts: Account[] = await defaultAccountApi().fetchAccounts(
      budgetId
    )
    return { accounts }
  }

  @Action({ commit: 'addAccount' })
  public async create(account: CreateAccount): Promise<Account> {
    const budgetId = router.currentRoute.params.budgetId
    const createdAccount = await defaultAccountApi().createAccount(
      budgetId,
      account
    )
    return createdAccount
  }

  @Action({ commit: 'setAccount' })
  public async updateAccount(account: UpdateAccount): Promise<Account> {
    const budgetId = router.currentRoute.params.budgetId
    const updatedAccount = await defaultAccountApi().updateAccount(
      budgetId,
      account
    )
    return updatedAccount
  }

  @Mutation
  private setBalance(payload: any): void {
    const accountId: string = payload.accountId
    if (!accountId) {
      return
    }
    const balance: Balance = payload.balance

    const balanceToUpdate = this.balances.find(b => b.accountId === accountId)
    if (balanceToUpdate) {
      balanceToUpdate.balance = balance.balance
      balanceToUpdate.clearedBalance = balance.clearedBalance
    } else {
      this.balances.push({
        accountId: accountId,
        balance: balance.balance,
        clearedBalance: balance.clearedBalance
      })
    }
  }

  @Action
  public async loadBalance(accountId?: string): Promise<any> {
    const budgetId = router.currentRoute.params.budgetId

    return defaultAccountApi()
      .fetchBalance(budgetId, accountId)
      .then(balance =>
        this.context.commit('setBalance', { accountId, balance })
      )
  }

  public get accountBalance() {
    return (accountId?: string) => {
      if (!accountId) {
        return this.summedBalance
      } else {
        return this.balance(accountId)
      }
    }
  }

  public get clearedAccountBalance() {
    return (accountId?: string) => {
      if (!accountId) {
        return this.summedClearedBalance
      } else {
        return this.clearedBalance(accountId)
      }
    }
  }

  private get summedBalance(): number {
    const sum = this.balances
      .map(b => (b.balance ? b.balance : 0))
      .reduce((sum, current) => sum + current, 0)
    return parseFloat(sum.toFixed(2))
  }

  private get summedClearedBalance(): number {
    const sum = this.balances
      .map(b => (b.clearedBalance ? b.clearedBalance : 0))
      .reduce((sum, current) => sum + current, 0)
    return parseFloat(sum.toFixed(2))
  }

  private get balance() {
    return (accountId: string) => {
      const balance = this.balances.find(b => b.accountId === accountId)
      if (balance) {
        return balance.balance ? balance.balance : 0
      } else {
        return 0
      }
    }
  }

  private get clearedBalance() {
    return (accountId: string) => {
      const balance = this.balances.find(b => b.accountId === accountId)
      if (balance) {
        return balance.clearedBalance ? balance.clearedBalance : 0
      } else {
        return 0
      }
    }
  }

  public get account() {
    return (accountId: string) => {
      return this.accounts.find(a => a.id === accountId)
    }
  }

  @Action
  public async reconcileAccount(
    accountReconciliation: AccountReconciliation
  ): Promise<void> {
    const budgetId = router.currentRoute.params.budgetId
    return defaultAccountApi()
      .reconcileAccount(budgetId, accountReconciliation)
      .then(() => {
        EventBus.$emit('accountReconciled', accountReconciliation.accountId)
        this.context.dispatch('loadBalance', accountReconciliation.accountId)
      })
  }
}

export const accountModule = getModule(AccountModule)
