











import { userModule } from '@/store/user/module'
import { EventBus } from '@/EventBus'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class App extends Vue {
  users = userModule

  created(): void {
    const electronSuffix = this.isElectron() ? '-electron' : ''
    this.$store.commit('initializeUserModule')
    EventBus.$on('toggle-theme', this.toggleDarkMode)
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      this.toggleDarkMode()
    } else {
      document.documentElement.setAttribute(
        'data-theme',
        'light' + electronSuffix
      )
    }
    if (this.ipcRenderer) {
      this.ipcRenderer.receive('app-focus', () => {
        ;(this.$root as any).hasFocus = true
      })
      this.ipcRenderer.receive('app-blur', () => {
        ;(this.$root as any).hasFocus = false
      })
    }
  }

  isDarkMode(): boolean {
    return (
      document.documentElement.getAttribute('data-theme')?.startsWith('dark') ||
      false
    )
  }

  isElectron(): boolean {
    return document.body.classList.contains('electron')
  }

  toggleDarkMode(): void {
    const electronSuffix = this.isElectron() ? '-electron' : ''
    if (this.isDarkMode()) {
      document.documentElement.setAttribute(
        'data-theme',
        'light' + electronSuffix
      )
      if (this.ipcRenderer) {
        this.ipcRenderer.send('set-theme', 'light')
      }
    } else {
      document.documentElement.setAttribute(
        'data-theme',
        'dark' + electronSuffix
      )
      if (this.ipcRenderer) {
        this.ipcRenderer.send('set-theme', 'dark')
      }
    }
  }

  get ipcRenderer(): any | null {
    return (window as any).ipcRenderer || null
  }
}
