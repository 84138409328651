import Router from 'vue-router'
import store from '@/store/store'
import { accountModule } from '@/store/accounts/module'
import { categoryModule } from '@/store/categories/module'
import { masterCategoryModule } from '@/store/mastercategories/module'
import { payeeModule } from '@/store/payees/module'

const accounts = accountModule
const categories = categoryModule
const masterCategories = masterCategoryModule
const payees = payeeModule

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () =>
        import(/* webpackChunkName: 'login-view' */ './views/LoginView.vue'),
      meta: {
        anonymous: true
      }
    },
    {
      path: '/start',
      name: 'start',
      component: () =>
        import(/* webpackChunkName: 'start-view' */ './views/StartView.vue')
    },
    {
      path: '*',
      redirect: { name: 'start' }
    },
    {
      path: '/:budgetId/budget/:yearMonth',
      name: 'monthly-budget',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: 'monthly-budget' */ './views/MonthlyBudgetView.vue'
        )
    },
    {
      path: '/:budgetId/transactions',
      name: 'transactions',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: 'transactions' */ './views/transactionlist/TransactionList.vue'
        )
    },
    {
      path: '/:budgetId/:accountId/transactions/',
      name: 'account-transactions',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: 'transactions' */ './views/transactionlist/TransactionList.vue'
        )
    },
    {
      path: '/:budgetId/:accountId/transactions/:transactionId',
      name: 'account-payment',
      props: true,
      component: () =>
        import(/* webpackChunkName: 'payment' */ './views/PaymentDetails.vue')
    },
    {
      path: '/:budgetId/transactions/:transactionId',
      name: 'payment',
      props: true,
      component: () =>
        import(/* webpackChunkName: 'payment' */ './views/PaymentDetails.vue')
    },
    {
      path: '/:budgetId/:accountId/transfers/:transactionId',
      name: 'account-transfer',
      props: true,
      component: () =>
        import(/* webpackChunkName: 'payment' */ './views/TransferDetails.vue')
    },
    {
      path: '/:budgetId/transfers/:transactionId',
      name: 'transfer',
      props: true,
      component: () =>
        import(/* webpackChunkName: 'payment' */ './views/TransferDetails.vue')
    },
    {
      path: '/:budgetId/reports',
      name: 'reports',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: 'payment' */ './views/reports/ReportsView.vue'
        )
    },
    {
      path: '/:budgetId/payees',
      name: 'payees',
      props: true,
      component: () =>
        import(/* webpackChunkName: 'payment' */ './views/PayeesView.vue')
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.anonymous)) {
    next()
  } else if (store.getters.isLoggedIn) {
    next()
  } else {
    next('/login')
  }
})

router.afterEach((to, from) => {
  if (to.name !== 'start' && to.name !== 'login') {
    accounts.loadAll()
  }
  if (to.name === 'monthly-budget') {
    categories.loadAllCategories()
    masterCategories.loadAllMasterCategories()
  } else if (
    to.name === 'transactions' ||
    to.name === 'account-transactions' ||
    to.name === 'payment' ||
    to.name === 'account-payment' ||
    to.name === 'transfer' ||
    to.name === 'account-transfer'
  ) {
    categories.loadAllCategories()
    masterCategories.loadAllMasterCategories()
    payees.loadAllPayees()
  } else if (to.name === 'payees') {
    payees.loadAllPayees()
  }
})

export default router
